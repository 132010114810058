import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import fakeResp from './fakeResp.json'

const ChessBoard = () => {
  // const [boardState, setBoardState] = useState([
  //   ['\u265C', '\u265E', '\u265D', '\u265B', '\u265A', '\u265D', '\u265E', '\u265C'],
  //   ['\u265F', '\u265F', '\u265F', '\u265F', '\u265F', '\u265F', '\u265F', '\u265F'],
  //   ['\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0'],
  //   ['\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1'],
  //   ['\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0'],
  //   ['\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1', '\u25A0', '\u25A1'],
  //   ['\u2659', '\u2659', '\u2659', '\u2659', '\u2659', '\u2659', '\u2659', '\u2659'],
  //   ['\u2656', '\u2658', '\u2657', '\u2655', '\u2654', '\u2657', '\u2658', '\u2656']
  // ]);
//
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [elo, setELO] = useState('');
  const [deploy_Jet, setDeployJet] = useState(false);
  const [deploy_Tank, setDeployTank] = useState(false);
  const [promote_Jet, setPromoteJet] = useState(false);
  const [promote_Tank, setPromoteTank] = useState(false);
  const [promote_Queen, setPromoteQueen] = useState(false);
  const [deploy, setDeploy] = useState(false);
  const [requestData, setReqDat] = useState([]);
  const [moveData, setMoveDat] = useState('');
  const [serverID, setserverID] = useState('');
  const [gameID, setgameID] = useState([]);
  const [errorText, setErrorText] = useState([]);
  const [invalidMove, setInvMove] = useState(false); // Declares if move is invalid, in which case the board is not updated and a error message comes out
  const [loading, setLoading] = useState(false); // Declares if move is invalid, in which case the board is not updated and a error message comes out
  //const parsedData = JSON.parse(fakeResp);
  // const fakeBoardData = fakeResp.board
  const [boardArray, setBoardArray] = useState([]);
  // const boardArray = Object.values(fakeBoardData).map(row => Object.values(row))


  // useEffect(() => {
  //   // Make API calls to get the current state of the chessboard
  //   // Update the state variable with the current state
  //   // The old/empty cell leave as a "" an empty string, as it auto updates to the coloured square
  //   // Change the button to upload your deployment/move choice
  //   // Update boardState to get new chess positions
  // }, []);

  useEffect(() => {
    new_game();
  }, []);

//

  async function new_game() {
    console.log("Sending POST for new game");
    setLoading(true)
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      };
      const response = await fetch('/newGame', requestOptions)
      // const response = await fetch('/newGame', requestOptions)
      const rsp_board_data = await response.json()
      console.log("new_game api rsp: " + response);
      console.log("new_game api board_data: " + rsp_board_data);
      let boardData = rsp_board_data.board
      setserverID(rsp_board_data.SERVER_SESSION_ID);
      setgameID(rsp_board_data.GAME_SESSION_ID);
      console.log({"boardData": boardData});

      let newBoardArray = Object.values(boardData).map(row => Object.values(row));
      console.log("newBoardArray", newBoardArray);
      setBoardArray(newBoardArray)
      setLoading(false)

    } catch (error) {
      console.error("New game API error: " + error);
      setLoading(false)
    }
  }

  async function new_game_black() {
    console.log("Sending POST for new game");
    setLoading(true)
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      };
      const response = await fetch('/newGameBlack', requestOptions)
      // const response = await fetch('/newGame', requestOptions)
      const rsp_board_data = await response.json()
      console.log("new_game api rsp: " + response);
      console.log("new_game api board_data: " + rsp_board_data);
      let boardData = rsp_board_data.board
      setserverID(rsp_board_data.SERVER_SESSION_ID);
      setgameID(rsp_board_data.GAME_SESSION_ID);
      console.log({"boardData": boardData});

      let newBoardArray = Object.values(boardData).map(row => Object.values(row));
      console.log("newBoardArray", newBoardArray);
      setBoardArray(newBoardArray)
      setLoading(false)

    } catch (error) {
      console.error("New game API error: " + error);
      setLoading(false)
    }
  }

  async function new_tactical_game() {
    console.log("Sending POST for new game");
    setLoading(true)
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      };
      const response = await fetch('/newTacticalWhite', requestOptions)
      // const response = await fetch('/newGame', requestOptions)
      const rsp_board_data = await response.json()
      console.log("new_game api rsp: " + response);
      console.log("new_game api board_data: " + rsp_board_data);
      let boardData = rsp_board_data.board
      setserverID(rsp_board_data.SERVER_SESSION_ID);
      setgameID(rsp_board_data.GAME_SESSION_ID);
      console.log({"boardData": boardData});

      let newBoardArray = Object.values(boardData).map(row => Object.values(row));
      console.log("newBoardArray", newBoardArray);
      setBoardArray(newBoardArray)
      setLoading(false)

    } catch (error) {
      console.error("New game API error: " + error);
      setLoading(false)
    }
  }

  async function new_tactical_game_black() {
    console.log("Sending POST for new game");
    setLoading(true)
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({})
      };
      const response = await fetch('/newTacticalBlack', requestOptions)
      // const response = await fetch('/newGame', requestOptions)
      const rsp_board_data = await response.json()
      console.log("new_game api rsp: " + response);
      console.log("new_game api board_data: " + rsp_board_data);
      let boardData = rsp_board_data.board
      setserverID(rsp_board_data.SERVER_SESSION_ID);
      setgameID(rsp_board_data.GAME_SESSION_ID);
      console.log({"boardData": boardData});

      let newBoardArray = Object.values(boardData).map(row => Object.values(row));
      console.log("newBoardArray", newBoardArray);
      setBoardArray(newBoardArray)
      setLoading(false)

    } catch (error) {
      console.error("New game API error: " + error);
      setLoading(false)
    }
  }

  async function make_move_request(move_data) {
    setErrorText("")
    setLoading(true)
    try {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(move_data)
      };
      const response = await fetch('/makeMove', requestOptions)
      // const response = await fetch('/makeMove', requestOptions)
      const rsp_board_data = await response.json()
      setLoading(false)
      console.log({"API make move response": rsp_board_data});
      setErrorText(rsp_board_data.emsg)
      let api_error = rsp_board_data.err
      if (api_error) {
        console.warn({"Server returned error state": rsp_board_data.emsg});
      } else {
        let boardData = rsp_board_data.board
        console.log({"boardData": boardData});

        let newBoardArray = Object.values(boardData).map(row => Object.values(row));
        console.log("newBoardArray", newBoardArray);
        setBoardArray(newBoardArray)
        setMoveDat(JSON.stringify(rsp_board_data.movedata))
        clear_inputs()

      }
      // Process the data
    } catch (error) {
      console.error("Make move API error: " + error);
      setLoading(false)
    }

  }

  async function get_move_hint(move_data) {
    setErrorText("")
    setLoading(true)
    try {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(move_data)
      };
      const response = await fetch('/movehint', requestOptions)
      const rsp_board_data = await response.json()
      setLoading(false)
      console.log({"API make move response": rsp_board_data});
      setErrorText(rsp_board_data.emsg)
      let api_error = rsp_board_data.err
      if (api_error) {
        console.warn({"Server returned error state": rsp_board_data.emsg});
      } else {
        let boardData = rsp_board_data.board
        console.log({"boardData": boardData});

        setMoveDat(JSON.stringify(rsp_board_data.movedata))
        clear_inputs()

      }
      // Process the data
    } catch (error) {
      console.error("Make move API error: " + error);
      setLoading(false)
    }

  }

  async function make_elo_request(elo_data) {
    setLoading(true)
    try {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(elo_data)
      };
      const response = await fetch('/setELO', requestOptions)
      const rsp_board_data = await response.json()
      setLoading(false)
      console.log({"API set elo response": rsp_board_data});
      let api_error = rsp_board_data.err
      if (api_error) {
        console.warn({"Server returned error state": rsp_board_data.emsg});
      }
    } catch (error) {
      console.error("Set ELO API error: " + error);
      setLoading(false)
    }

  }

  function clear_inputs() {
    setDeployJet(false)
    setDeployTank(false)
    setPromoteJet(false)
    setPromoteTank(false)
    setPromoteQueen(false)
    setFrom("")
    setTo("")
    document.getElementById("inputFrom").focus();
  }


  const handleFrom = (e) => { // Set the piece to be moved
    setFrom(e.target.value);
  };

  const handleELO = (e) => { // Set the piece to be moved
    setELO(e.target.value);

  };

  const handleTo = (e) => { // Set the move Destination
    setTo(e.target.value);
  };

  const handleELOSET = () => {
    let req_elo = elo
    const payload = {
      target_elo:       req_elo,
      gsid:       gameID,
      ssid:       serverID
    }
    make_elo_request(payload)
  }


  const handleMove = () => { // Locks in and initiates in your move parameters
    const request = ["From", "To", "Deployment", "Promotion"];
    let req_from    = ""
    let req_to      = ""
    let req_deploy  = ""
    let req_promote = ""

    if (from !== "" && to !== "") {
      req_from = from;
      req_to = to;

      if (deploy_Jet) {
        req_deploy = "J"
      } else if (deploy_Tank) {
        req_deploy = "T"
      }

      if (promote_Jet) {
        req_promote = "J";
      } else if (promote_Tank) {
        req_promote = "T";
      } else if (promote_Queen) {
        req_promote = "Q";
      }

      const payload = {
        from:       req_from,
        to:         req_to,
        deployment: req_deploy,
        promotion:  req_promote,
        gsid:       gameID,
        ssid:       serverID
      };

      setInvMove(false);
      // setReqDat(JSON.stringify(payload));
      // setReqDat(payload);
      console.log({"sending payload to make move: ":payload});
      make_move_request(payload)
    } else {
      setInvMove(true);
    }
  };

  const handleMoveHint = () => {

      const payload = {
        gsid:       gameID,
        ssid:       serverID
      };
      console.log({"sending payload to make move: ":payload});
      get_move_hint(payload)

  };

  const handleNewGameWhite = () => { // Locks in and initiates in your move parameters
    new_game()
  };

  const handleNewGameBlack = () => { // Locks in and initiates in your move parameters
    new_game_black()
  };

  const handleNewTacticalGameWhite = () => { // Locks in and initiates in your move parameters
    new_tactical_game()
  };

  const handleNewTacticalGameBlack = () => { // Locks in and initiates in your move parameters
    new_tactical_game_black()
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleMove();
    }
  }



  const renderBoard = () => {
    console.log("render board...");
    console.log({"board_array": boardArray});
    if (boardArray.length == 0) {
      console.error("Empty board array...");
      const board = []
      board.push(<tr key={"loading"}>{"Loading..."}</tr>);
      return board
    }
    const board = [];
    const top_row = [];
    top_row.push(<th key={0}>{'\u0020'}</th>);
    for (let c = 1; c < 9; c++) {
      top_row.push(<th key={c}>{String.fromCharCode(65 + c - 1)}</th>);
    }
    board.push(<tr key={0}>{top_row}</tr>);

    for (let i = 0; i < 8; i++) {
      const row = [8-i];

      for (let j = 0; j < 8; j++) {
        const piece = boardArray[i][j];
        const isBlack = (i + j) % 2 === 1;

        if (piece === "") {
          row.push(<td key={`${i}${j}`}> {isBlack ? '\u25A0' : '\u25A1'}</td>);
        } else {row.push(<td key={`${i}${j}`}> {piece}</td>);}
      }

      board.push(<tr key={i}>{row}</tr>);
    }

    return board;
  };

  return (
    <table>
      {invalidMove && <p style={{fontSize: "30px"}}>Invalid Move</p>}
      {loading && <p style={{fontSize: "30px"}}>Loading...</p>}
      <button onClick={handleNewGameWhite}>New game as White</button><br/>
      <button onClick={handleNewGameBlack}>New game as Black</button><br/>
      <button onClick={handleNewTacticalGameWhite}>New Tactical game as White</button><br/>
      <button onClick={handleNewTacticalGameBlack}>New Tactical game as Black</button><br/>
      <button onClick={handleMoveHint}>Move Hint</button><br/>
      <p style={{fontSize: "30px"}}>{requestData}</p>
      <p style={{fontSize: "30px", color: "red"}}>{errorText}</p>

      <tbody><p style={{fontSize: "30px"}}>{renderBoard()}</p></tbody>

      <input type='text' placeholder='From' id="inputFrom" value={from} onChange={handleFrom} />
      <input type='text' placeholder='To' id="inputTo" value={to} onChange={handleTo} onKeyUp={handleKeyPress} /><br/>

      <label htmlFor="Jet">
      Deploy Jet
      <input type='checkbox' id="deployJet" checked={deploy_Jet} onChange={() => setDeployJet(!deploy_Jet)} />
      </label><br/>

      <label htmlFor="Tank">
      Deploy Tank
      <input type='checkbox' id="deployTank" checked={deploy_Tank} onChange={() => setDeployTank(!deploy_Tank)} />
      </label><br/>

      <label htmlFor="Jet">
      Promote Pawn to Jet
      <input type='checkbox' id="promoteJet" checked={promote_Jet} onChange={() => setPromoteJet(!promote_Jet)} />
      </label><br/>

      <label htmlFor="Tank">
      Promote Pawn to Tank
      <input type='checkbox' id="promoteTank" checked={promote_Tank} onChange={() => setPromoteTank(!promote_Tank)} />
      </label><br/>

      <label htmlFor="promQ">
      Promote Pawn to Queen
      <input type='checkbox' id="promoteQueen" checked={promote_Queen} onChange={() => setPromoteQueen(!promote_Queen)} />
      </label><br/>

      <button onClick={handleMove}>Move</button><br/>

      <p style={{fontSize: "15px"}}>{moveData}</p>

      <p>{from}</p>
      <p>{to}</p>
      <p>{deploy ? "deployed" : ""}</p>
      <p>To play - input the values in the designated boxes and select deployment/promotion options.
         If none are available, the boxes won't do anything. In case of wrong move, message pops up and the board is not updated.
      </p>

      <input type='text' placeholder='ELO' id="inputELO" value={elo} onChange={handleELO} />
      <button onClick={handleELOSET}>Set ELO</button><br/>

    </table>
  );
};
export default ChessBoard
